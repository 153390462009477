.backgroundblue{
    background-color: #071b30;
    height: auto;
    color: white;

}

.backcontrast{
    background-color: white;
    color: #071b30;
    width: fit-content;
    margin: auto;
}

.boldtxt{
    font-weight: 800;
}

.activity{
    width: 200px;
    height: 180px;
    /* background-color: #e7e7e7; */
}

.activity div{
    transform: translate(0, 35%);
}

.activity img{
    margin-bottom: 5px;
    width: 80px;
    height: 80px;
}
