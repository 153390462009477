
@font-face {
    font-family: "Gothan-Bold";
    src: url("../Fonts/Gotham-Font/GothamBold.ttf");
  }

  @font-face {
    font-family: "Gothan-Light";
    src: url("../Fonts/Gotham-Font/Gotham-Light.otf");
  }


.fuente{
    color: rgb(255, 255, 255);
    font-size:large;
    font-weight: bold;
    font-family: "Gothan-Bold";
}

.fuentemin{
  color: rgb(255, 255, 255);
  font-size:small;
  font-family: "Gothan-Bold";
}

.fuentesimple{
  color: rgb(255, 255, 255);
  font-family: "Gothan-Bold";
}

.indicador{
    color: rgb(255, 157, 0);

    font-weight: bold;
    font-family: "Gothan-Bold";

}


.ruta{
    color: rgb(255, 255, 255);
    font-size:medium;
    font-weight: bold;
    font-family: "Gothan-Bold";

}

.inicio{
  color: rgb(255, 255, 255);
  font-size:medium;
  font-weight: bold;
  font-family: "Gothan-Bold";
  font-size: 50px;

}

.header {
  max-height: 20%;
  background-color: #091D35; 
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  /*background-color: hsl(212, 100%, 11%);*/
  /* background: linear-gradient(to right,#091d35de, #091D35de); */
  /* background: linear-gradient(to right,#091d35de, #091D35de), url(../img/pantalla.png)  center; */
    
  }

  .ancho{
    width: 160px;
  }

  .header .navbar {
    background-color: transparent !important;
  }

  .dropdown-menu-cus{
    min-width: 10px !important;
  }

  .navbar-nav > li > .dropdown-menu { 
    background-color: #091D35; 
    border-radius: 20px;
  
  }

  .fuente-link{
    color: rgb(255, 255, 255);
    font-size:smaller;
    font-weight: bold;
    font-family: "Gothan-light";

}



.barra{
  position: relative;
}
 

.barra::before {
  content: ’’;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #b47dcd, #e878a2, #eb85ab);
  z-index: 1;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-in-out;
}

.barra:hover::before {
  transform: scaleX(1);
}



  

